import React, { Component } from 'react';
import { Form, Input, Button, message } from 'antd'
import './index.scss'
import logo from '../assets/logo.jpg'

import { loginInterview } from '../utils/api'


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layout: {
                labelCol: { span: 0 },
                wrapperCol: { span: 8 },
            },
            tailLayout: {
                wrapperCol: { offset: 0, span: 16 },
            }
        }
    }
    
    onFinish = (v) => {
        if (v.username && v.password) {
            loginInterview({
                username: v.username,
                password: v.password,
            }).then(res => {
                if (res.code === 1) {
                    message.error('用户名或密码错误');
                } else {
                    this.props.history.push('/index?id=' + res.data.exam_id)
                }
            })
        } else {
            message.error('请输入用户名或密码！');
        }
    }

    onFinishFailed = (error) => {
        console.error('error', error)
    }

    render() {
        const { layout, tailLayout } = this.state
        return (
            <div className='login-contanier' >
                <img src={logo} alt="logo" />
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    className='from'
                >
                    <Form.Item
                        label="账号"
                        name="username"
                        rules={[{ required: true, message: '请输入账号!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="密码"
                        name="password"
                        rules={[{ required: true, message: '请输入密码!' }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">提交</Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default Login;