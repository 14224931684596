import React, { Component } from 'react';
import { Button, Modal, message } from 'antd';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';
import './index.scss'
import {
    getInterviewTime, uploadImg, uploadInterviewImg, checkEnterStatus,
    addCheatsTimes, addCheats, examFinish,
    getProblems
} from '../utils/api'
let video
let interId
let takePh
class Exam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            laTexData: [],
            id: null,
            time: (new Date()).toLocaleString(),
            during: 0,
            cheat: -1,
            visible: false,
            round: null
        }
    }
    componentDidMount() {
        const cur = this
        video = document.getElementById("video")
        if (process.env.NODE_ENV == 'production') {
            this.getMedia()
        }
        let str = this.props.location.search.split('=')
        let id = str[1].split('&')[0]
        let round = str[2]

        document.body.scrollTo(0, 0)

        this.setState({
            id,
            round
        })
        checkEnterStatus({
            id,
            round
        }).then(res => {
            if (res.code == 1) {
                this.props.history.push('/login')
                message.error(res.msg)
            }
        })
        getProblems({ id }).then(res => {
            let da = res.data
            for (let i = 0; i < da.length; i++) {
                let ar = this.dealLaTex(da[i].stem)
                da[i].arr = ar
            }
            cur.setState({
                laTexData: da
            })
        })
        let times
        getInterviewTime({ id }).then(res => {
            times = (round == 1 ? new Date(res.data.start_time).getTime() : new Date(res.data.tech_time).getTime())
            cur.setState({
                round: res.data.round
            })
        })
        interId = setInterval(() => {
            this.setState({
                time: (new Date()).toLocaleString(),
            })
            this.countTime(times)
        }, 1000)
        takePh = setInterval(() => {
            let canvas = document.getElementById("canvas");
            let ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, 500, 300);
            var imgData = canvas.toDataURL();
            uploadImg({
                imgData: imgData,
                name: 'name_img',
            }).then(res => {
                uploadInterviewImg({
                    img: res.data,
                    id: this.state.id
                })
            })
        }, 1000 * 60 * 5)
        window.addEventListener('resize', this.onResize)
        window.onblur = function () {
            addCheatsTimes({ id })
            cur.setState({
                visible: true,
                cheat: cur.state.cheat + 1
            })
        }
    }

    componentWillUnmount() {
        clearInterval(interId)
        clearInterval(takePh)
    }

    onResize = () => {
        if (this.state.cheat == -1) {
            this.setState({
                cheat: 0
            })
        } else {
            if (this.state.visible == false) {
                addCheatsTimes({ id: this.state.id })
                this.setState({
                    visible: true,
                    cheat: this.state.cheat + 1
                })
            }
        }
    }

    handleOk = e => {
        this.setState({
            visible: false,
        });
    }

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    }

    countTime(time) {
        let getNowTime = new Date()
        let t = getNowTime.getTime() - time
        let hour = Math.floor((t / 3600000) % 24)
        let min = Math.floor((t / 60000) % 60)
        let sec = Math.floor((t / 1000) % 60)
        let round = this.state.round
        // 一面如果已经超时了，二面会自动跳出
        if (round == 1) {
            if (hour >= 3) {
                this.props.history.push('/upload?id=' + this.state.id + '&time=' + new Date().getTime())
            }
            if (hour) {
                this.setState({
                    during: hour + '时' + min + '分' + sec + '秒'
                })
            } else {
                this.setState({
                    during: min + '分' + sec + '秒'
                })
            }
        } else if (round == 2) {
            if (hour >= 1) {
                this.props.history.push('/upload?id=' + this.state.id + '&time=' + new Date().getTime())
            }
            if (hour) {
                this.setState({
                    during: hour + '时' + min + '分' + sec + '秒'
                })
            } else {
                this.setState({
                    during: min + '分' + sec + '秒'
                })
            }
        }
    }
    dealLaTex = (str) => {
        let s2 = str.split('$$')
        for (let i = 0; i < s2.length; i++) {
            if (i % 2 === 0) {
                let ss = s2[i].split('$')
                for (let j = 0; j < ss.length; j++) {
                    if (j % 2 === 0) {
                    } else {
                        ss[j] = '@@' + ss[j]
                    }
                }
                s2[i] = ss
            } else {
                s2[i] = '@@@' + s2[i]
            }
        }
        let s = s2.flat().filter(function (s) { return s && s.trim() })
        return s
    }

    submit = () => {
        this.setState({
            showConfirm: true,
        });
    }

    confirmOk = () => {
        addCheats({
            times: this.state.cheat == -1 ? 0 : this.state.cheat,
            id: this.state.id
        })
        examFinish({
            time: this.state.during,
            id: this.state.id,
            type: 2
        })
        this.props.history.push('/upload?id=' + this.state.id + '&time=' + new Date().getTime())
    }

    confirmCancel = () => {
        this.setState({
            showConfirm: false
        })
    }

    getMedia() {
        let constraints = {
            video: { width: 500, height: 300 },
            audio: true
        }
        let promise = navigator.mediaDevices.getUserMedia(constraints);
        promise.then(function (MediaStream) {
            video.srcObject = MediaStream;
            video.play();
        }).catch(function (PermissionDeniedError) {
            console.log(PermissionDeniedError);
        })
        this.setState({
            showVideo: true
        })
    }
    render() {
        const { laTexData, round } = this.state
        return (
            <div className='contanier'>
                {round == 2 ? null : <div className='part-title'>part B 解答题</div>}
                <div className='time'>{this.state.time}</div>
                <div className='time'>考试已经开始：{this.state.during} </div>
                <video id="video" className='vidieos' width="500px" height="300px" autoPlay="autoplay"></video>
                <canvas id="canvas" className='canvas' width="500px" height="300px"></canvas>
                {laTexData.map((data, index) => {
                    return (
                        <div className='exam-wrapper'>
                            <span>{index + 1} . </span>
                            {data.arr.map((item, index) => {
                                if (item.indexOf('@@@') > -1) {
                                    return <BlockMath key={index}>{String.raw`${item.split("@@@")[1]}`}</BlockMath>
                                } else if (item.indexOf('@@') > -1) {
                                    return <InlineMath key={index}>{String.raw`${item.split("@@")[1]}`}</InlineMath>
                                } else {
                                    return <span dangerouslySetInnerHTML={{ __html: item }}></span>
                                }
                            })}
                        </div>
                    )
                })}
                <Button type="primary" style={{ marginBottom: 800, marginTop: 30 }} onClick={this.submit.bind(this)} >提交答案</Button>
                <Modal
                    maskStyle={{ background: '#000' }}
                    title="禁止拖动屏幕"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <p> 请在答题界面安心作答！</p>
                </Modal>
                <Modal
                    title="解答题提交！"
                    visible={this.state.showConfirm}
                    okText='确认'
                    cancelText='返回继续作答'
                    onOk={this.confirmOk}
                    onCancel={this.confirmCancel}
                >
                    <p>解答题部分只能提交一次，提交之后不可回退或再次修改答案！</p>
                </Modal>
            </div>

        );
    }
}

export default Exam;