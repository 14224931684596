import React, { Component } from 'react'
import { Result, Button } from 'antd';
import 'katex/dist/katex.min.css';
import './index.scss'

class Overtime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null
        }
    }
    componentDidMount() {
        let id = this.props.location.search.split('=')[1]
        this.setState({
            id
        })

    }
    goUpload = () => {
        this.props.history.push('/upload?id=' + this.state.id)
    }
    goBack = () => {
        this.props.history.push('/exam?id=' + this.state.id)
    }

    render() {
        return (
            <div>
                {/* 您还有${22}分钟可用于作答， */}
                <Result
                    status="warning"
                    title="3小时作答时间已结束！"
                    subTitle={`请交卷！禁止回退，回退会导致你退出笔试系统！`}
                    extra={[
                        <Button type="primary" key="console" onClick={this.goUpload.bind(this)}  >上传答卷</Button>,
                        // <Button key="buy" onClick={this.goBack.bind(this)} >返回继续作答</Button>,
                    ]}
                />
            </div>
        );
    }
}

export default Overtime;