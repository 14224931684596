export const timeFormat = (ts, dateJoint, HMS) => {
    if (ts == '' || ts == undefined || ts == null) {
      return
    }
    let time = new Date(ts)
    let strTimer = ''
    let strMouth = time.getMonth() + 1
    let strDay = time.getDate()
    strTimer += time.getFullYear() + dateJoint
    strTimer += (strMouth < 10 ? (0 + '' + strMouth) : strMouth) + dateJoint
    strTimer += (strDay < 10 ? 0 + '' + strDay : strDay)
    if (HMS === '%H:%M') {
      strTimer += '  ' + time.getHours() + ':'
      strTimer += (time.getMinutes() >= 0 && time.getMinutes() <= 9) ? '0' + time.getMinutes() : time.getMinutes()
    } else if (HMS === '%H:%M:%S') {
      strTimer += ' '
      strTimer += (time.getHours() >= 0 && time.getHours() <= 9) ? '0' + time.getHours() + ':' : +time.getHours() + ':'
      strTimer += (time.getMinutes() >= 0 && time.getMinutes() <= 9) ? '0' + time.getMinutes() + ':' : time.getMinutes() + ':'
      strTimer += (time.getSeconds() >= 0 && time.getSeconds() <= 9) ? '0' + time.getSeconds() : time.getSeconds()
    }
    return strTimer
  }
  