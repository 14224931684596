import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom'

import Index from './pages/index'
import Login from './pages/login'
import Choice from './pages/choice'
import Exam from './pages/exam'
import Upload from './pages/upload'
import Submit from './pages/submit'
import Overtime from './pages/overtime'
import IT from './pages/it'
import TWAP from './pages/twap'
import TWAPEXAM from './pages/twapExam'

function App() {
  return (
    <div className="App">
    <Router>
      <Route path='/index'  component={Index} />
      <Route path='/login' component={Login} />
      <Route path='/Choice'  component={Choice} />
      <Route path='/exam' component={Exam} />
      <Route path='/upload' component={Upload} />
      <Route path='/submit' component={Submit} />
      <Route path='/overtime' component={Overtime} />
      <Route path='/it' component={IT} />
      <Route path='/twap' component={TWAP} />
      <Route path='/twapExam' component={TWAPEXAM} />
    </Router>
    </div>
  )
}

export default App;
