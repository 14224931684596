//导入我们封装好的axios 
import service from './request'

// post
export const loginInterview = info => service.post('/exam/api/loginInterview', info);
export const getInterviewTime = info => service.post('/exam/api/getInterviewTime', info);
export const uploadImg = info => service.post('/exam/api/uploadImg', info);
export const uploadInterviewImg = info => service.post('/exam/api/uploadInterviewImg', info);
export const checkEnterStatus = info => service.post('/exam/api/checkEnterStatus', info);
export const getPaper = info => service.post('/exam/api/getPaper', info);
export const addCheatsTimes = info => service.post('/exam/api/addCheatsTimes', info);
export const submitChoice = info => service.post('/exam/api/submitChoice', info);
export const addCheats = info => service.post('/exam/api/addCheats', info);
export const examFinish = info => service.post('/exam/api/examFinish', info);
export const getProblems = info => service.post('/exam/api/getProblems', info);
export const uploadAnswer = info => service.post('/exam/api/uploadAnswer', info);
export const uploadTestPaper = info => service.post('/exam/api/uploadTestPaper', info);
export const submitItAnswer = info => service.post('/exam/api/submitItAnswer', info);
export const getItQuestion = info => service.post('/exam/api/getItQuestion', info);
export const uploadTwapTempAnswer = info => service.post('/exam/api/uploadTwapTempAnswer', info);
